<template>
  <div class="micro-controllers">
    <b-overlay :show="removingMicroController">
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            sm="12"
            offset-sm="0"
            offset-md="6"
            offset-lg="6"
          >
            <div
              v-if="paginatedMicroControllers.length"
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="pageChanged(1)"
              />
              <b-button
                variant="primary"
                @click="pageChanged(1)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <Teleport to="#header-buttons">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          style="top : 0; right: 0;"
          @click="$refs.addMicroController.show()"
        >
          <feather-icon
            icon="PlusCircleIcon"
            size="15"
          />
          Add Micro Controller
        </b-button>
      </Teleport>
      <b-card no-body>
        <b-skeleton-table
          v-if="gettingMicroControllersData"
          :rows="paginationObject.limit"
          :columns="3"
          :table-props="{
            bordered: true,
            striped: true,
            tableClass: 'skeleton-loading-table',
          }"
        />
        <div
          v-else-if="paginatedMicroControllers.length === 0"
          class="text-center mt-5 font-large-1 text-black-50 mb-5"
        >No Micro Controller Found</div>
        <b-table
          v-else
          ref="mediaTable"
          :items="paginatedMicroControllers"
          :per-page="paginationObject.limit"
          :responsive="true"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
        >
          <template #cell(Serial)="data">
            <truncate
              :text="data.item.serial_key"
              :length="50"
            />
          </template>
          <template #cell(Site)="data">
            <div>{{ data.item.site && data.item.site.name }}</div>
          </template>
          <template #cell(Address)="data">
            <truncate
              :text="data.item.site && data.item.site.address"
              :length="50"
            />
          </template>
          <template #cell(Group)="data">
            <div class="text-center">
              {{ data.item.group && data.item.group.name }}
            </div>
          </template>
          <template #cell(Actions)="data">
            <div class="text-center">
              <feather-icon
                class="cursor-pointer"
                icon="Trash2Icon"
                size="18"
                @click="removeMicroController(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card>
      <div class="mt-0">
        <div class="text-nowrap">
          <!-- pagination -->
          <CustomPagination
            v-if="paginatedMicroControllers.length"
            :total="paginationObject.total"
            :per-page="paginationObject.limit"
            :page="paginationObject.offset"
            @perPageChanged="perPageChanged"
            @pageChanged="pageChanged"
          />
        </div>
      </div>
      <AddMicroController
        ref="addMicroController"
        @getPaginatedMicroControllers="getPaginatedMicroControllers(paginationObject)"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BOverlay, BCard, BSkeletonTable, BTable, BButton, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { deleteMicroController, getAllMicroControllers } from '@/api/admin/simmiIOTEngine/microControllers'
import { confirmDeletion, showToast } from '@/common/global/functions'
import Truncate from '@/common/components/Truncate.vue'
import CustomPagination from '@/common/components/CustomPagination.vue'
import AddMicroController from '@/components/microControllers/AddMicroController.vue'

export default {
  name: 'Index',
  components: {
    AddMicroController,
    CustomPagination,
    Truncate,
    BOverlay,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BSkeletonTable,
  },
  data() {
    return {
      allMicroControllers: [],
      paginatedMicroControllers: [],
      gettingMicroControllersData: false,
      tableColumns: [
        { key: 'Serial' },
        { key: 'Site' },
        { key: 'Address' },
        {
          key: 'Group',
        },
        {
          key: 'Actions',
          style: 'float: right',
          thClass: 'pull-center text-center',
        },
      ],
      removingMicroController: false,
      searchQuery: '',
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
    }
  },
  mounted() {
    this.getPaginatedMicroControllers(this.paginationObject)
  },
  methods: {
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getPaginatedMicroControllers(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getPaginatedMicroControllers(this.paginationObject)
    },
    async getPaginatedMicroControllers({ offset, limit }) {
      this.gettingMicroControllersData = true
      try {
        const {
          data: { success, response },
        } = await getAllMicroControllers(offset, limit, this.searchQuery)
        if (success) {
          this.paginatedMicroControllers = response.data
          this.paginationObject.total = response.count
          if (
            this.paginatedMicroControllers.length === 0
              && this.paginationObject.offset !== 1
          ) {
            this.paginationObject.offset = 1
            this.getPaginatedMicroControllers(this.paginationObject)
          }
        } else {
          throw new Error('Error getting micro controllers')
        }
      } catch (e) {
        console.error(e)
        showToast(
          'Micro Controller',
          'Error getting micro controllers!',
          'danger',
        )
      } finally {
        this.gettingMicroControllersData = false
      }
    },
    removeMicroController(id) {
      confirmDeletion().then(async result => {
        if (result.value) {
          try {
            this.removingMicroController = true
            const { data } = await deleteMicroController(id)
            if (data.success) {
              showToast('Micro Controller', 'Micro Controller has been removed successfully!', 'success')
            } else {
              showToast('Micro Controller', data.message, 'danger')
            }
          } catch (e) {
            showToast('Micro Controller', 'Error removing micro controller!', 'danger')
          } finally {
            this.removingMicroController = false
            this.getPaginatedMicroControllers(this.paginationObject)
          }
        }
      })
    },
  },
}
</script>

<style scoped></style>
