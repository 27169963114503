import simmiIOT from '@/auth/simmiIOT'

const SITES = 'sites'

const getAllSites = () => simmiIOT.get(`${SITES}`)

const getAllSitesByGroupId = groupId => simmiIOT.get(`${SITES}/admin/group/${groupId}`)

// eslint-disable-next-line import/prefer-default-export
export { getAllSites, getAllSitesByGroupId }
