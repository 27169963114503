<template>
  <b-modal
    v-model="showModal"
    no-close-on-backdrop
    centered
    :size="size"
    hide-footer
    hide-header
    class="position-relative"
  >
    <b-overlay
      variant="white"
      spinner-variant="primary"
      opacity="0.5"
      :show="loading"
    >
      <div
        class="
          modal1
          d-flex
          justify-content-center
          align-content-center align-items-center
          m-1
          mb-2
        "
      >
        <b-row class="d-block w-75">
          <div class="close-div mr-1 mt-1">
            <b-button
              variant="transparent"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div
            class="
              d-inline-block d-flex
              justify-content-center
              align-content-center align-items-center
              mb-1
            "
          >
            <span class="font-weight-bolder heading1 mt-0">
              <slot name="header" />
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <div class="w-100">
              <slot
                name="body"
              />
            </div>
          </div>
          <div class="d-flex justify-content-around">
            <div class="w-100 mr-1">
              <b-button
                block
                variant="secondary"
                @click="hide"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="15"
                />
                Cancel
              </b-button>
            </div>
            <div class="w-100">
              <b-button
                block
                variant="primary"
                @click="$emit('submit')"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="15"
                />
                Submit
              </b-button>
            </div>
          </div>
        </b-row>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BButton, BModal, BRow, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'Modal1',
  components: {
    BRow,
    BButton,
    BModal,
    BOverlay,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    show() {
      this.showModal = true
    },
    hide() {
      this.showModal = false
    },
  },
}
</script>

<style scoped lang="scss">
.modal1 {
  .close-div {
    justify-content: end;
    display: flex;
    float: right;
    margin-right: 0 !important;
    position: absolute;
    padding-right: 0 !important;
    top: -15px;
    right: 0;
  }
}
</style>
