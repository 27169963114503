<template>
  <div
    class="truncate-component"
  >
    <span
      v-b-tooltip.hover.right="toolTipText"
      class="cursor-pointer"
      @click="copyToClipboard"
      @mouseover="toolTipText = 'Click to copy!'"
    >{{ truncate(text) }}</span>
  </div>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import Vue from 'vue'

Vue.use(VueClipboard)

export default {
  name: 'Truncate',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    copy: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      toolTipText: 'Click to copy!',
    }
  },
  computed: {
    isTruncated() {
      return this.text.length > this.length
    },
  },
  methods: {
    truncate(value) {
      const clamp = '...'
      const node = document.createElement('div')
      node.innerHTML = value
      const content = node.textContent
      return content.length > this.length
        ? content.slice(0, this.length) + clamp
        : content
    },
    copyToClipboard() {
      this.$copyText(this.text).then(() => {
        this.toolTipText = 'Copied!'
      }).catch(() => {
        this.toolTipText = 'Error!'
      })
    },
  },
}
</script>

<style scoped></style>
