import simmiIOT from '@/auth/simmiIOT'

const MICRO_CONTROLLER = 'micro-controller'

const getAllMicroControllers = (offset, limit, searchQuery) => simmiIOT.get(`${MICRO_CONTROLLER}/all?page=${offset - 1}&limit=${limit}&searchQuery=${searchQuery}`)

const getUnRegisteredMicroControllers = () => simmiIOT.get(`${MICRO_CONTROLLER}/clients/unregistered`)

const addMicroControllerToSite = payload => simmiIOT.post(`${MICRO_CONTROLLER}`, payload)

const deleteMicroController = id => simmiIOT.get(`${MICRO_CONTROLLER}/delete/${id}`)

// eslint-disable-next-line import/prefer-default-export
export {
  getAllMicroControllers, getUnRegisteredMicroControllers, addMicroControllerToSite, deleteMicroController,
}
