<template>
  <div class="add-micro-controller">
    <Modal1
      ref="addMicroControllerModal1"
      :loading="showLoading"
      size="lg"
      @submit="submit"
    >
      <template #header>
        Micro Controller
      </template>
      <template #body>
        <validation-observer ref="addMicroControllerForm">
          <b-form class="add-service-provider-form">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Group"
                  label-for="group"
                >
                  <validation-provider
                    v-slot="{ errors, classes }"
                    name="group"
                    rules="required"
                  >
                    <v-select
                      id="group"
                      v-model="payload.group_id"
                      :class="classes"
                      input-id="group"
                      placeholder="Please select group"
                      :reduce="group => group.id"
                      class="form-input"
                      label="name"
                      :state="errors.length > 0 ? false : null"
                      :options="allActiveGroups"
                      @input="fetchAllSitesByGroupId"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Site"
                  label-for="site"
                >
                  <validation-provider
                    v-slot="{ errors, classes }"
                    name="site"
                    rules="required"
                  >
                    <v-select
                      id="site"
                      v-model="payload.site.id"
                      :disabled="!payload.group_id"
                      :class="classes"
                      input-id="site"
                      placeholder="Please select site"
                      :reduce="site => site.id"
                      class="form-input"
                      label="name"
                      :options="groupSites"
                      :state="errors.length > 0 ? false : null"
                      @input="fetchUnRegisteredMicroControllers"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Controller"
                  label-for="controller"
                >
                  <validation-provider
                    v-slot="{ errors, classes }"
                    name="controller"
                    rules="required"
                  >
                    <v-select
                      id="site"
                      v-model="payload.serial_key"
                      :disabled="!payload.site.id"
                      :class="classes"
                      input-id="controller"
                      placeholder="Please select controller"
                      :reduce="controller => controller.serial_Key"
                      class="form-input"
                      label="serial_Key"
                      :options="microControllers"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #option="option">
                        <span>{{ option.serial_Key }}</span>
                      </template>
                      <template #selected-option="option">
                        <span>{{ option.serial_Key }}</span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </Modal1>
  </div>
</template>
<script>
import {
  BForm, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Modal1 from '@/common/components/modals/Modal1.vue'
import { getAllIOTGroups } from '@/api/admin/simmiIOTEngine/userGroups'
import { showToast } from '@/common/global/functions'
import { getAllSitesByGroupId } from '@/api/admin/simmiIOTEngine/sites'
import { addMicroControllerToSite, getUnRegisteredMicroControllers } from '@/api/admin/simmiIOTEngine/microControllers'

export default {
  name: 'AddMicroController',
  components: {
    Modal1,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      required,
      showLoading: false,
      allActiveGroups: [],
      groupSites: [],
      microControllers: [],
      payload: {
        group_id: null,
        serial_key: null,
        username: 'username',
        password: 'password',
        token: 'token',
        site: {
          id: null,
        },
      },
    }
  },
  methods: {
    show() {
      this.$refs.addMicroControllerModal1.show()
      this.payload = {
        serial_key: null,
        username: 'username',
        password: 'password',
        token: 'token',
        site: {
          id: null,
        },
      }
      this.fetchAllActiveGroups()
    },
    hide() {
      this.$refs.addMicroControllerModal1.hide()
    },
    async submit() {
      this.$refs.addMicroControllerForm.validate().then(async validated => {
        if (validated) {
          try {
            this.showLoading = true
            const {
              data: { success, message },
            } = await addMicroControllerToSite(this.payload)
            if (success) {
              showToast('Micro Controller', 'Micro controller has been added to site!')
              this.hide()
              this.$emit('getPaginatedMicroControllers')
            } else {
              showToast('Micro Controller', message, 'danger')
            }
          } catch (e) {
            showToast('Micro Controller', 'Error adding micro controller!', 'danger')
          } finally {
            this.showLoading = false
          }
        }
      })
    },
    async fetchAllActiveGroups() {
      try {
        this.showLoading = true
        const {
          data: { success, response },
        } = await getAllIOTGroups()
        if (success) {
          this.allActiveGroups = response
        } else {
          throw new Error('Error getting iot groups!')
        }
      } catch (e) {
        console.error(e)
        showToast(
          'Micro Controllers',
          'Error getting active groups, please try again later!',
          'danger',
        )
        this.hide()
      } finally {
        this.showLoading = false
      }
    },
    async fetchAllSitesByGroupId(groupId) {
      if (groupId) {
        this.payload.site.id = null
        try {
          this.showLoading = true
          const {
            data: { success, response },
          } = await getAllSitesByGroupId(groupId)
          if (success) {
            this.groupSites = response
          } else {
            throw new Error('Error getting iot groups!')
          }
        } catch (e) {
          console.error(e)
          showToast(
            'Micro Controllers',
            'Error getting sites against group, please try again later!',
            'danger',
          )
          this.hide()
        } finally {
          this.showLoading = false
        }
      }
    },
    async fetchUnRegisteredMicroControllers() {
      if (this.payload.site.id) {
        try {
          this.showLoading = true
          const {
            data: { success, response },
          } = await getUnRegisteredMicroControllers()
          if (success) {
            this.microControllers = response
          } else {
            throw new Error('Error getting micro controllers!')
          }
        } catch (e) {
          console.error(e)
          showToast(
            'Micro Controllers',
            'Error getting micro controllers, please try again later!',
            'danger',
          )
        } finally {
          this.showLoading = false
        }
      }
    },
  },
}
</script>

<style scoped></style>
